import React from "react";

import AccountLayout from "~/layouts/account";
import { LinkComponent } from "~/components/Links";
import { Heading } from "~/components/Typography";
import { AddClassForm } from "~/components/forms";

import styles from "~/styles/class/styles.module.css"


const AddClass = (): JSX.Element => {
  return (
    <AccountLayout activeLink="/dashboard">
      <div className={styles.Content}>
        <LinkComponent href="/dashboard" arrow>
          Back to class list
        </LinkComponent>
        <Heading color="ms" level={2} underline>
          Add new class
        </Heading>
        <p className="text-gray-600 text-base font-medium">
          To add a new class, complete the box below. You will want a new code for each class. You could name the class by period number (e.g., period 1, period 2, and so forth). Alternatively, you could include the class name and period number (e.g, gen bio period 1). These class names are just to help you identify different classes. You will need to provide the class code to students so they can access the student materials.
        </p>
        <AddClassForm />
      </div>
    </AccountLayout>
  );
};

export default AddClass;
